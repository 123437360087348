<template>
  <section class="overview panel-section">
    <ul class="ul1 flex">
      <li
        :style="`min-width:${item.width}`"
        class="card-background li1 flex"
        v-for="(item, index) in overViewList"
        :key="`overViewList${index}`"
      >
        <img :src="item.icon" alt="" />
        <div class="right flex column justifySpaceBetween">
          <div class="name">{{ item.name }}</div>
          <div class="res-content">
            <span class="number">{{ $ComFunc.formatNumber(item.number) }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </li>
    </ul>
    <ul class="card-background ul2 flex alignCenter">
      <li class="refresh flex column alignCenter justifySpaceAround">
        <div class="name fontW600">今日统计</div>
        <img @click="getOverview" class="refresh-icon" :src="refreshIcon" />
      </li>
      <span class="divider"></span>
      <div class="ul2-d1 flex justifySpaceBetween alignCenter">
        <li>
          <div class="name">已处理交易</div>
          <div class="res-content">
            <span element-loading-spinner="el-icon-loading" v-loading="listLoading" class="number">{{countData.successTx || 0}}</span>
            <span class="unit">笔</span>
          </div>
        </li>
        <span class="divider"></span>
        <li>
          <div class="name">队列待处理</div>
          <div class="res-content">
            <span  element-loading-spinner="el-icon-loading" v-loading="listLoading" class="number">{{ countData.pendingTx || 0 }}</span>
            <span class="unit">笔</span>
          </div>
        </li>
        <span class="divider"></span>
        <li>
          <div class="name">链工作状态</div>
          <span class="status" :class="workStatusCss">正常</span>
        </li>
      </div>
    </ul>
    <ul class="card-background ul3 flex alignCenter">
      <li class="refresh flex column alignCenter justifySpaceAround">
        <div class="fontW600 title">热门渠道</div>
        <img @click="getOverview" class="refresh-icon" :src="refreshIcon" />
      </li>
      <span class="divider h122"></span>
      <div class="table1">
        <Table
          :baseData="channelList"
          :tableHeader="tableHeader"
          :listLoading="listLoading"
          
        ></Table>
      </div>
    </ul>
  </section>
</template>

<script>
//数字资产开放网络--概览
import API from "@/api/api_digitalAsset";

export default {
  name: "Overview",
  components: {},
  data() {
    return {
      baseData: [],
      total: 0,
      isShow: false,
      listLoading: false,
      tableHeader: this.tableHeader.overview.tableHeader,
      refreshIcon: require("@/assets/icons/overview/refresh.png"),
      overViewList: [],
      countData: {},
      channelList: []
    };
  },
  computed: {
    workStatusCss() {
      return "";
    },
  },
  methods: {
    getOverview() {
      this.listLoading = true
      API.getOverview().then(({ data }) => {
        this.setCardData(data.data || {});
        this.countData = data.data || {};
        this.setChannelList(data.data || {});
      }).finally(()=>{
        this.listLoading = false
      });
    },
    setCardData(data) {
      this.overViewList = [
        {
          name: "链节点数",
          number: data.nodeNum || 0,
          width: "212px",
          icon: require("@/assets/icons/overview/img-gzt-1.png"),
          unit: "个",
        },
        {
          name: "累计服务机构数",
          number: data.shopNum || 0,
          width: "260px",
          icon: require("@/assets/icons/overview/img-gzt-2.png"),
          unit: "个",
        },
        {
          name: "累计发行智能合约",
          number: data.assetNum || 0,
          width: "276px",
          icon: require("@/assets/icons/overview/img-gzt-3.png"),
          unit: "",
        },
        {
          name: "累计发行资产通证",
          number: data.shareNum || 0,
          width: "276px",
          icon: require("@/assets/icons/overview/img-gzt-4.png"),
          unit: "",
        }
      ];
    },
    setChannelList(data) {
      data.lists.length =5
      this.channelList = data.lists || [];
      this.channelList.map((item) => {
        item.appNo = `${item.shopName}/${item.appId}`;
        return item;
      });
    }
  },
  created() {},
  activated() {
    this.getOverview();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

@mixin fontWight($fontWight) {
  font-weight: $fontWight;
}
//card背景样式
.card-background {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 63, 158, 0.05);
  border-radius: 8px;
}
//字段样式
.name {
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #616875;
}
//数字样式
.number {
  font-family: "DIN";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #333a47;
}
//单位样式
.unit {
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #8f96a3;
  margin-left: 4px;
}
.divider {
  display: inline-block;
  opacity: 0.3;
  height: 56px;
  border-right: 1px solid;
  background: #8f96a3;
}
.fontW600 {
  font-weight: 600 !important;
}
.h122 {
  height: 122px;
}
.refresh-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.refresh {
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #616875;
  flex: 0 0 164px;
}
.li1 {
  height: 118px;
  padding: 22px 24px;
  margin-right: 32px;
  img {
    width: 74px;
    height: 74px;
    margin-right: 24px;
  }
}
.ul2 {
  width: 1120px;
  margin: 24px 0;
  padding: 22px 0;
  height: 118px;
  .ul2-d1 {
    height: 100%;
    width: 100%;
    padding: 0 166px 0 80px;
  }
  li {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.ul3 {
  width: 1120px;
  /* height: 240px; */
  padding: 22px 40px 22px 0;
  li {
    height: 100%;
    padding: 60px 0;
  }
}
.table1 {
  width: 836px;
  margin-left: 80px;
  /deep/ .el-table {
    border: 1px solid #e4e7ed;
    border-bottom: 0;
    th {
      height: 48px;
      padding: 0;
      background: #ffffff;
      font-family: "Microsoft YaHei UI";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #333a47;
      /* border: 1px solid #e3e5e8; */
    }
  }
}
.status {
  width: 46px;
  height: 24px;
  background: #02bb75;
  border-radius: 2px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  &.busy {
    background-color: #ff9428;
  }
  &.crowd {
    background-color: #ff3f4b;
  }
}

.wrap {
  .panel-section {
    background-color: transparent;
    box-shadow: none;
    overflow: initial;
    .res-content {
      font-size: 0;
    }
  }
  .title {
    margin-bottom: 14px;
  }
}
</style>
